import React, { useEffect } from 'react'
import { pageTitle } from '../../helper'
import Card from '../Card'
import Card2 from '../Card2'
import Cta from '../Cta'
import PageHeading from '../PageHeading'
import PricingTableList from '../PricingTable/PricingTableList'
import Div from '../Div'
import SectionHeading from '../SectionHeading'
import TestimonialSlider from '../Slider/TestimonialSlider'
import Spacing from '../Spacing'

export default function ServicesPage() {
  pageTitle('Service');
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  return (
    <>
      <PageHeading
        title='Services'
        bgSrc='images/service_hero_bg.jpeg'
        pageLinkText='Services'
      />
      <Spacing lg='100' md='80' />
      {/* <Div className='cs-shape_wrap_4'>
        <Div className="cs-shape_4"></Div>
        <Div className="cs-shape_4"></Div>
        <Div className="container">
          <Div className="row">
            <Div className="col-xl-4">
              <SectionHeading
                title='Services we can help you with'
                subtitle='What Can We Do'
              />
              <Spacing lg='90' md='45' />
            </Div>
            <Div className='col-xl-8'>
              <Div className='row'>
                <Div className='col-lg-3 col-sm-6 cs-hidden_mobile'></Div>
                <Div className='col-lg-3 col-sm-6'>
                  <Card
                    title='Strategy and Planning'
                    link='/service/ui-ux-design'
                    src='/images/service_1.jpeg'
                    alt='Service'
                    info='We analyze your crypto project to create a customized strategy that defines objectives, targets audiences, and develops an implementation roadmap to maximize success in the competitive crypto space.'
                  />

                  <Spacing lg='0' md='30' />
                </Div>
                <Div className='col-lg-3 col-sm-6 cs-hidden_mobile'></Div>
                <Div className='col-lg-3 col-sm-6'>
                  <Card
                    title='Content Creation'
                    link='/service/reactjs-development'
                    src='/images/service_2.jpeg'
                    alt='Service'
                    info=' We specialize in creating high-quality content for crypto projects, including whitepapers, social media posts, and promo videos, to build brand awareness, credibility and drive growth.'
                  />
                  <Spacing lg='0' md='30' />
                </Div>
                <Div className='col-lg-3 col-sm-6'>
                  <Card
                    title='Social Media Management'
                    link='/service/digital-marketing'
                    src='/images/service_3.jpeg'
                    alt='Service'
                    info='We create effective plans, engaging content, and run targeted ads to manage your social media accounts and ensure consistent messaging that resonates with your target audience.'
                  />
                  <Spacing lg='0' md='30' />
                </Div>
                <Div className='col-lg-3 col-sm-6 cs-hidden_mobile'></Div>
                <Div className='col-lg-3 col-sm-6'>
                  <Card
                    title='Community Growth and Management'
                    link='/service/technology'
                    src='/images/service_4.jpeg'
                    alt='Service'
                    info='Our team builds and maintains a strong community around your project through engaging content, campaigns, and events, and penetration in the Asian market.'
                  />
                  <Spacing lg='0' md='30' />
                </Div>
                <Div className='col-lg-3 col-sm-6 cs-hidden_mobile'></Div>
                <Div className='col-lg-3 col-sm-6 cs-hidden_mobile'></Div>
                <Div className='col-lg-3 col-sm-6'>
                  <Card
                    title='Consultancy'
                    link='/service/consultancy'
                    src='/images/service_5.jpeg'
                    alt='Service'
                    info='Our experts provide technical support and assistance, including blockchain development, smart contract auditing, security testing, and more, to optimize your project performance.'
                  />
                  <Spacing lg='0' md='30' />
                </Div>
                <Div className='col-lg-3 col-sm-6 cs-hidden_mobile'></Div>
                <Div className='col-lg-3 col-sm-6'>
                  <Card
                    title='Partnerships and Promotions'
                    link='/service/creative-design'
                    src='/images/service_6.jpeg'
                    alt='Service'
                    info='We identify strategic partnerships and promotion opportunities to help you stand out in the competitive crypto space and maximize your potential for growth.'
                  />
                  <Spacing lg='0' md='30' />
                </Div>
                <Div className='col-lg-3 col-sm-6'>
                  <Card
                    title='Public Relations'
                    link='/service/digital-marketing'
                    src='/images/service_3.jpeg'
                    alt='Service'
                    info="We ensure effective communication of your project's message through media outreach, press releases, and crisis management to establish credibility and manage your project's reputation."
                  />
                  <Spacing lg='0' md='30' />
                </Div>
                <Div className='col-lg-3 col-sm-6 cs-hidden_mobile'></Div>
                <Div className='col-lg-3 col-sm-6'>
                  <Card
                    title='Project Operations'
                    link='/service/technology'
                    src='/images/service_4.jpeg'
                    alt='Service'
                    info='Our team manages the day-to-day operations of your project, including team coordination, resource allocation, and project management to ensure a streamlined and efficient process.'
                  />
                  <Spacing lg='0' md='30' />

                  
            
        
      
      






                  

                </Div>
                <Div className='col-lg-3 col-sm-6 cs-hidden_mobile'></Div>
                <Div className='col-lg-3 col-sm-6 cs-hidden_mobile'></Div>
              </Div>
            </Div>
          </Div>
          <Spacing lg='50' md='30' />
        </Div>
      </Div> */}
      {/* SECOND CONTAINER */}

      <Div className="container">
      {/* <Div className="cs-shape_4"></Div> */}
        <Div className="col-xl-4">
          <SectionHeading
            title='Services we offer '
            subtitle='What Can We Do'
          />
          <Spacing lg='90' md='45' />
        </Div>
        <Div className="row">
          <Div className='col-xl-12'>
            <Div className='row'>
              {/* <Div className='col-lg-3 col-sm-6 cs-hidden_mobile'></Div> */}
              <Div className='col-lg-4 col-sm-6'>
                <Card2
                  title='Strategy and Planning'
                  link='#'
                  src='/images/service_y1.png'
                  alt='Service'
                  info=' Our crypto project analysis services provide a personalized approach to maximize success in the competitive crypto space. We analyze strengths, weaknesses, opportunities, and threats, define objectives and target audience, and develop an implementation roadmap. Contact us for tailored analysis and development strategy. '
                />

                <Spacing lg='0' md='30' />
              </Div>
              {/* <Div className='col-lg-3 col-sm-6 cs-hidden_mobile'></Div> */}
              <Div className='col-lg-4 col-sm-6'>
                <Card2
                  title='Content Creation'
                  link='#'
                  src='/images/service_y2.png'
                  alt='Service'
                  info=' ChainPeak provides expert content creation services for crypto projects, including whitepapers, blogs, social media, press releases, and videos. Our high-quality content boosts brand recognition, credibility, and attracts new users and investors. With a focus on content marketing, we help you achieve your goals by effectively targeting your audience. Elevate your crypto project with our compelling content.
                  '
                />
                <Spacing lg='30' md='30' />
              </Div>
              <Div className='col-lg-4 col-sm-6'>
                <Card2
                  title='Community Growth and Management'
                  link='#'
                  src='/images/service_y3.png'
                  alt='Service'
                  info='Grow and penetrate the Asian market with your crypto project with our expert community-building services. We create engaging content, manage social media, and provide tailored customer support for a dedicated and active community. Contact us to achieve long-term success.'
                />
                <Spacing lg='0' md='30' />
              </Div>
              {/* <Div className='col-lg-3 col-sm-6 cs-hidden_mobile'></Div> */}
              <Div className='col-lg-4 col-sm-6'>
                <Card2
                  title='Social Media Management'
                  link='#'
                  src='/images/service_y5.png'
                  alt='Service'
                  info='Boost your online presence with our social media management service. We create engaging content, run targeted ads, and manage your accounts for consistent messaging that resonates with your audience. Stay ahead of the competition and maximize your project potential. Choose our expert management for your social media needs.'
                />
                <Spacing lg='0' md='30' />
              </Div>
              {/* <Div className='col-lg-3 col-sm-6 cs-hidden_mobile'></Div>
    <Div className='col-lg-3 col-sm-6 cs-hidden_mobile'></Div> */}
              <Div className='col-lg-4 col-sm-6'>
                <Card2
                  title='Technical Assistance'
                  link='#'
                  src='/images/service_y9.png'
                  alt='Service'
                  info='Our team of experts provides technical support and assistance to help you navigate complex technical issues and optimize your project performance. We offer a range of services, including blockchain development, smart contract auditing, security testing, and more. With ChainPeak, you can be confident that your project is in good hands and can focus on achieving your business goals.'
                />
                <Spacing lg='0' md='30' />
              </Div>
              {/* <Div className='col-lg-3 col-sm-6 cs-hidden_mobile'></Div> */}
              <Div className='col-lg-4 col-sm-6'>
                <Card2
                  title='Partnerships and Promotions'
                  link='#'
                  src='/images/service_y6.png'
                  alt='Service'
                  info='Our team specializes in identifying strategic partnerships and promotion opportunities that align with your project goals and values. We develop tailored strategies through co-marketing campaigns, influencer outreach, or targeted promotions, helping you stand out in the highly competitive crypto space and maximize your potential for growth.'
                />
                <Spacing lg='0' md='30' />
              </Div>
              <Spacing lg='30' md='30' />
              <Div className='col-lg-4 col-sm-6'>
                <Card2
                  title='Public Relations'
                  link='#'
                  src='/images/service_y4.png'
                  alt='Service'
                  info="We ensure effective communication of your project's message through media outreach, press releases, and crisis management to establish credibility and manage your project's reputation."
                />
                <Spacing lg='0' md='30' />
              </Div>
              {/* <Div className='col-lg-3 col-sm-6 cs-hidden_mobile'></Div> */}
              <Div className='col-lg-4 col-sm-6'>
                <Card2
                  title='Project Operations'
                  link='#'
                  src='/images/service_y8.png'
                  alt='Service'
                  info='Our team manages the day-to-day operations of your project, including team coordination, resource allocation, and project management to ensure a streamlined and efficient process.'
                />
                <Spacing lg='0' md='30' />
              </Div>
              <Div className='col-lg-4 col-sm-6'>
                <Card2
                  title='VC and LEAD Generation'
                  link='#'
                  src='/images/service_y.png'
                  alt='Service'
                  info='We use our network of VCs, angel investors, and industry leaders to find investment opportunities and generate leads for your project. We collaborate with you to develop a targeted outreach strategy that boosts exposure and improves the chances of securing funding.'
                />
                <Spacing lg='0' md='30' />
              </Div>
              <Spacing lg='30' md='30' /> 
              <Div className='col-lg-4 col-sm-6'>
                <Card2
                  title='Offline Events and Conferences'
                  link='#'
                  src='/images/service_y.png'
                  alt='Service'
                  info='We have the expertise to assist you in organizing and accomplishing successful gatherings, ranging from intimate gatherings to extensive conferences. We take care of all the necessary components, such as choosing the ideal location, recruiting speakers, and promoting the event.'
                />
                <Spacing lg='0' md='30' />
              </Div>
              <Div className='col-lg-4 col-sm-6'>
                <Card2
                  title='Listings'
                  link='#'
                  src='/images/service_y6.png'
                  alt='Service'
                  info='Boost your crypto project visibility and reach with ChainPeak. We specialize in listing your token on top exchanges and data sites, attracting investors and enhancing credibility. Experience seamless processes and expert guidance for maximum growth.'
                />
                <Spacing lg='0' md='30' />
              </Div>
              <Div className='col-lg-4 col-sm-6'>
                <Card2
                  title='Regional Outreach'
                  link='#'
                  src='/images/service_y1.png'
                  alt='Service'
                  info='Expand your crypto project global reach with ChainPeak regional outreach service. Our expertise in the Asian market enables tailored strategies, local community engagement, influencer relationships, and targeted events. Establish a strong user base in China, Japan, Korea, India, and beyond.'
                />
                <Spacing lg='0' md='30' />
              </Div>

              {/* <Div className='col-lg-3 col-sm-6 cs-hidden_mobile'></Div>
              <Div className='col-lg-3 col-sm-6 cs-hidden_mobile'></Div> */}
            </Div>
          </Div>
        </Div>
      </Div>
      <Spacing lg='150' md='80' />

      <Spacing lg='125' md='55' />
      <TestimonialSlider />
      <Spacing lg='150' md='80' />
      <Div className="container">
        <Cta
          title="Lets make something great together"
          btnText='Contact US'
          btnLink='/contact'
          bgSrc='/images/boxx3.jpg'
        />
      </Div>
    </>
  )
}
