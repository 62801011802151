
import emailjs from '@emailjs/browser';

const SERVICE_ID = 'service_levm9ve';
const TEMPLATE_ID = 'template_es4wr9o';


// export function sendEmail(email, fullName, projectType, mobile, projectDetails) {
//   const templateParams = {
//     email: email,
//     fullName: fullName,
//     projectType: projectType,
//     mobile: mobile,
//     projectDetails: projectDetails,
//   };


export function sendEmail(formData) {
  const templateParams = {
    email: formData.email,
    fullName: formData.fullName,
    projectType: formData.projectType,
    mobile: formData.mobile,
    projectDetails: formData.projectDetails,
  };

  emailjs.send(SERVICE_ID, TEMPLATE_ID, templateParams, 'qn1WuNSXR5yNbMNqe')
    .then((response) => {
      console.log('Email sent successfully:', response);
    }, (error) => {
      console.error('Email sending failed:', error);
    });
}

