import { Icon } from '@iconify/react'
import React, { useEffect } from 'react'
import { Link, useParams } from 'react-router-dom'
import { pageTitle } from '../../helper'
import Cta from '../Cta'
import PageHeading from '../PageHeading'
import Div from '../Div'
import Sidebar from '../Sidebar.jsx'
import Spacing from '../Spacing'

export default function BlogDetailsPage() {
  const params = useParams()
  pageTitle('Blog');
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  return (
    <>
    {/* Start Page Heading Section */}
      <PageHeading 
        title='Blog'
        bgSrc='/images/blog_details_hero_bg.jpeg'
        pageLinkText={params.blogDetailsId}
      />
      {/* End Page Heading Section */}

      {/* Start Blog Details */}
      <Spacing lg='150' md='80'/>
      <Div className="container">
        <Div className="row">
          <Div className="col-lg-8">

            {/* Start Details Post Content */}
            <Div className="cs-post cs-style2">
              <Div className="cs-post_thumb cs-radius_15">
                <img src="/images/post_5.jpeg" alt="Post" className="w-100 cs-radius_15" />
              </Div>
              <Div className="cs-post_info">
                <Div className="cs-post_meta cs-style1 cs-ternary_color cs-semi_bold cs-primary_font">
                  <span className="cs-posted_by">18 May 2023</span>
                  <Link to="/blog" className="cs-post_avatar">Marketing</Link>
                </Div>
                <h2 className="cs-post_title">EDCON 2023: The Ultimate Destination for Blockchain Marketing, Paving the Path to Thriving Success</h2>
                <p>As the annual event of the global Ethereum community, EDCON (Ethereum Community Development Conference) has long been well-known and attracted numerous experts, investors, and developers in the blockchain industry. EDCON 2023 will not only become a benchmark in the blockchain field but also the best platform for ambitious blockchain projects to showcase and accumulate popularity. As a professional blockchain marketing agency, ChainPeak will provide you with strategic advice on how to leverage EDCON 2023 to your advantage.</p>
                <blockquote className="cs-primary_font">
                Compete with Industry Leaders: EDCON’s stage will be unprecedentedly valuable, as the founder of Ethereum, Vitalik Buterin, will be present to give a keynote speech. Standing on the same stage, your project will attract countless eyeballs and gain the trust of the audience.
                  <small>https://medium.com/@chainpeak/edcon-2023-the-ultimate-destination-for-blockchain-marketing-paving-the-path-to-thriving-success-24107efd4bb6</small>
                </blockquote>
                <p>Entering EDCON 2023, your networking, technology, and marketing will be comprehensively upgraded. In five days, your project will receive unprecedented exposure opportunities and countless attention. At the same time, you will also gain an in-depth understanding of the latest technology advancements and keep up with the latest developments in the industry. From the perspective of a professional blockchain project marketing agency, EDCON 2023 is undoubtedly an event not to be missed. We wish you fruitful marketing results at this conference.</p>
                <Div className="row">
                  <Div className="col-md-6">
                    <img src="/images/blog_details_img_1.jpeg" alt="Blog Details" className="cs-radius_15 w-100" />
                    <Div className="cs-height_45 cs-height_lg_45" />
                  </Div>
                  <Div className="col-md-6">
                    <img src="/images/blog_details_img_2.jpeg" alt="Blog Details" className="cs-radius_15 w-100" />
                    <Div className="cs-height_45 cs-height_lg_45" />
                  </Div>
                </Div>
                <h3>ChainPeak’s Marketing Services: The Professional Touch Your Project Needs</h3>
                <p>In the world of business, marketing is essential for any project to succeed. This is where ChainPeak’s professional marketing services come in. With years of experience in the industry, ChainPeak has the expertise and know-how to take your project to new heights. Here are some reasons why you should consider partnering with ChainPeak:</p>
                <p>Effective Sales Psychology According to Simon Hazeldine’s book, “Neuro-Sell: How Neuroscience Can Power Your Sales Success,” successful sales involve appealing to the emotional needs of potential clients. By understanding your project’s pain points and presenting ChainPeak’s services as the solution to those needs, we can help you achieve sales success.</p>
              </Div>
            </Div>
            {/* End Details Post Content */}

            {/* Start Comment Section
            <Spacing lg='30' md='30'/>
            <h2 className="cs-font_50 cs-m0">Leave A Reply</h2>
            <Spacing lg='5' md='5'/>
            <p className="cs-m0">Your email address will not be published. Required fields are marked *</p>
            <Spacing lg='40' md='30'/>
            <form className="row">
              <Div className="col-lg-6">
                <label>Full Name*</label>
                <input type="text" className="cs-form_field" />
                <Div className="cs-height_20 cs-height_lg_20" />
                <Div data-lastpass-icon-root="true" style={{position: 'relative !important', height: '0px !important', width: '0px !important', float: 'left !important'}} /></Div>
              <Div className="col-lg-6">
                <label>Email*</label>
                <input type="text" className="cs-form_field" />
                <Div className="cs-height_20 cs-height_lg_20" />
              </Div>
              <Div className="col-lg-12">
                <label>Website*</label>
                <input type="text" className="cs-form_field" />
                <Div className="cs-height_20 cs-height_lg_20" />
              </Div>
              <Div className="col-lg-12">
                <label>Write Your Comment*</label>
                <textarea cols={30} rows={7} className="cs-form_field" />
                <Div className="cs-height_25 cs-height_lg_25" />
              </Div>
              <Div className="col-lg-12">
                <button className="cs-btn cs-style1">
                  <span>Send Message</span>
                  <Icon icon="bi:arrow-right" />               
                </button>
              </Div>
            </form> */}
            {/* End Comment Section */}
          </Div>
          <Div className="col-xl-3 col-lg-4 offset-xl-1">
            {/* Start Sidebar */}
            <Spacing lg='0' md='80'/>
            <Sidebar />
            {/* End Sidebar */}
          </Div>
        </Div>
      </Div>
      <Spacing lg='150' md='80'/>
      {/* Start Blog Details */}

      {/* Start CTA Section */}
      <Div className="container">
        <Cta 
          title="Let's collaborate and create <br />something awesome together." 
          btnText='Apply For Meeting' 
          btnLink='/contact' 
          bgSrc='/images/cta_bg.jpeg'
        />
      </Div>
      {/* End CTA Section */}
    </>
  )
}
