import React from 'react'
import { Link } from 'react-router-dom'
import { Icon } from '@iconify/react';
import Div from '../Div';

export default function SocialWidget() {
  return (
    <Div className="cs-social_btns cs-style1">
      <a href="https://twitter.com/chainpeak" className="cs-center" ><Icon icon="fa6-brands:twitter" />  </a>
      {/* <a href="https://t.me/chainpeak" className="cs-center" ><Icon icon="fa6-brands:telegram" />  </a> */}
      <a href="https://medium.com/@chainpeak" className="cs-center" ><Icon icon="fa6-brands:medium" />  </a>
      {/* <Link to='https://medium.com/@chainpeak' className="cs-center">
        <Icon icon="fa6-brands:medium" />
      </Link> */}
      {/* <Link to={{pathname: `https://twitter.com/chainpeak` }} target="_blank" className="cs-center">
        <Icon icon="fa6-brands:twitter" />               
      </Link> */}
      {/* <Link to='/' className="cs-center">
        <Icon icon="fa6-brands:telegram" />              
      </Link>
      <Link to='/' className="cs-center">
        <Icon icon="fa6-brands:discord" />
      </Link> */}
    </Div>
  )
}
