// import React from 'react'
// import Div from '../Div'
// import './logolist.scss'

// export default function LogoList() {
//   const partnerLogos = [
//     {
//       src: '/images/partner_1.svg', 
//       alt:'Partner'
//     },
//     {
//       src: '/images/partner_2.svg', 
//       alt:'Partner'
//     },
//     {
//       src: '/images/partner_3.svg', 
//       alt:'Partner'
//     },
//     {
//       src: '/images/partner_4.svg', 
//       alt:'Partner'
//     },
//     {
//       src: '/images/partner_5.svg', 
//       alt:'Partner'
//     }
//   ]
//   return (
//     <Div className="cs-partner_logo_wrap">
//       {partnerLogos.map((partnerLogo, index)=><div className="cs-partner_logo" key={index}><img src={partnerLogo.src} alt={partnerLogo.alt} /></div>)}
//     </Div>
//   )
// }


// import React from 'react';
// import Div from '../Div';

// import './logolist.scss';

// export default function LogoList() {
//   const logos = Array.from({ length: 18 }, (_, i) => ({
//     src: `/images/logo/logo${i + 1}.png`,
//     alt: `Logo ${i + 1}`,
//   }));

//   return (
//     <Div className="cs-logo_list">
//       {Array.from({ length: 5 }).map((_, i) => (
//         <Div className="cs-logo_row" key={i}>
//           {logos.slice(i * 5, i * 5 + 5).map((logo, j) => (
//             <Div className="cs-logo_col" key={j}>
//               <img src={logo.src} alt={logo.alt} />
//             </Div>
//           ))}
//         </Div>
//       ))}
//     </Div>
//   );
// }


import React from 'react';
import Div from '../Div';

import './logolist.scss';

function LogoRow({ logos }) {
  return (
    <Div className="cs-logo_row">
      {logos.map((logo, i) => (
        <Div className="cs-logo_col" key={i}>
          <img src={logo.src} alt={logo.alt} />
        </Div>
      ))}
    </Div>
  );
}

export default function LogoList() {
  const clients = Array.from({ length: 12 }, (_, i) => ({
    src: `/images/logo/clients/logo${i + 1}.png`,
    alt: `Client Logo ${i + 1}`,
  }));

  const partners = Array.from({ length: 15 }, (_, i) => ({
    src: `/images/logo/partners/logox${i + 1}.png`,
    alt: `Partner Logo ${i + 1}`,
  }));

  return (
    <Div className="cs-logo_container">
      <Div className="cs-logo_section">
        <h2>Our Clients</h2>
        <LogoRow logos={clients.slice(0, 3)} />
        <LogoRow logos={clients.slice(3, 6)} />
        <LogoRow logos={clients.slice(6, 9)} />
        <LogoRow logos={clients.slice(9, 12)} />
        {/* <LogoRow logos={clients.slice(11,13)} /> */}
      </Div>
      <Div className="cs-logo_separator" />
      <Div className="cs-logo_section">
        <h2>Our Partners</h2>
        <LogoRow logos={partners.slice(0, 3)} />
        <LogoRow logos={partners.slice(3, 6)} />
        <LogoRow logos={partners.slice(6, 9)} />
        <LogoRow logos={partners.slice(9, 12)} />
        <LogoRow logos={partners.slice(12, 15)} />
      </Div>
    </Div>
  );
}
