import React, { useEffect } from 'react';
import { pageTitle } from '../../helper';
import Cta from '../Cta';
import FunFact from '../FunFact';
import PageHeading from '../PageHeading';
import Div from '../Div';
import SectionHeading from '../SectionHeading';
// import TeamSlider from '../Slider/TeamSlider';
import Spacing from '../Spacing';

export default function AboutPage() {
  pageTitle('About');

  const funfaceData = [
    {
      title: 'Global User reached',
      factNumber: '5million',
    },
    {
      title: 'Happy clients',
      factNumber: '120',
    },
    {
      title: 'Team Members',
      factNumber: '60',
    },
    {
      title: 'Project completed',
      factNumber: '120',
    },
  ];
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      {/* Start Page Heading Section */}
      <PageHeading
        title="About Us"
        bgSrc="images/about_hero_bg.jpeg"
        pageLinkText="About Us"
      />
      {/* End Page Heading Section */}

      {/* Start About Section */}
      <Spacing lg="150" md="80" />
      <Div className="container">
        <Div className="row">
          <Div className="col-xl-5 col-lg-7">
            <SectionHeading
              title="About Our Agency"
              subtitle="Your trusted partner for business"
            >
              <Spacing lg="30" md="20" />
              <p className="cs-m0">
              {/* Join our dynamic team of experts and be a part of the exciting world of cryptocurrency marketing. We are constantly looking for talented individuals who are passionate about blockchain technology and want to make a difference in the industry. */}
              
 

              </p>
              <Spacing lg="30" md="30" />
              <Div className="cs-separator cs-accent_bg"></Div>
              <Spacing lg="30" md="20" />
              <p className="cs-m0">
              Welcome to our company, where we are committed to providing the best marketing solutions for the cryptocurrency industry. With over 5 million global users, we have established ourselves as a leading force in the market, helping our clients achieve their marketing goals and grow their projects. 

Our team consists of over 60 professionals from various time zones, who bring a wealth of experience and expertise to the table. 

With over years of industry experience, we have a deep understanding of the challenges and opportunities that the crypto market presents. 

              </p>
              <Spacing lg="25" md="40" />
            </SectionHeading>
          </Div>
          <Div className="col-lg-5 offset-xl-2">
            <img
              src="/images/about_img_1.jpeg"
              alt="About"
              className="w-100 cs-radius_15"
            />
            <Spacing lg="25" md="25" />
          </Div>
          <Div className="col-lg-7">
            <img
              src="/images/about_img_2.jpeg"
              alt="About"
              className="w-100 cs-radius_15"
            />
            <Spacing lg="25" md="25" />
          </Div>
          <Div className="col-lg-5">
            <img
              src="/images/about_img_3.jpeg"
              alt="About"
              className="w-100 cs-radius_15"
            />
            <Spacing lg="25" md="25" />
          </Div>
        </Div>
      </Div>
      <Spacing lg="75" md="55" />
      {/* End About Section */}

      {/* Start Fun Fact Section */}
      <Div className="container">
        <FunFact
          title="Looking to boost your cryptocurrency marketing efforts?"
          subtitle="
          ChainPeak specializes in providing you with the tools and strategies needed to maximize your reach and grow your business
          "
          data={funfaceData}
        />
      </Div>
      {/* End Fun Fact Section */}

      {/* Start Why Choose Section */}
      <Spacing lg="100" md="80" />
      <Div className="container">
        <Div className="row">
          <Div className="col-xl-5 col-lg-6">
            <Div className="cs-image_layer cs-style1">
              <Div className="cs-image_layer_in">
                <img
                  src="/images/about_img_4.jpeg"
                  alt="About"
                  className="w-100 cs-radius_15"
                />
              </Div>
            </Div>
            <Spacing lg="0" md="40" />
          </Div>
          <Div className="col-xl-5 offset-xl-1 col-lg-6">
            <SectionHeading
              title="Why Choose Us"
              subtitle="Highly experienced people with us"
            >
              <Spacing lg="30" md="20" />
              <p className="cs-m0">
              We specialize in delivering customized marketing solutions to our clients, tailored to their unique needs and objectives. Whether it's social media management, public relations, strategic partnerships, or lead generation, we work closely with our clients to ensure that they achieve their marketing goals and stay ahead of the competition. 

Our success in the Asian market is a testament to our expertise and commitment to excellence. We have a strong presence in the region and have helped numerous clients establish themselves as reputable players in the industry. 

              </p>
              <Spacing lg="15" md="15" />

              <p className="cs-m0">
              Want to join our dynamic team of experts and be a part of the exciting world of cryptocurrency marketing. We are constantly looking for talented individuals who are passionate about blockchain technology and want to make a difference in the industry.
              <Spacing lg="15" md="15" />

              Apply today at : careers@chainpeak.io

              </p>
              <Spacing lg="30" md="30" />
              <Div className="cs-separator cs-accent_bg"></Div>
              <Spacing lg="25" md="0" />
            </SectionHeading>
          </Div>
        </Div>
      </Div>
      {/* End Why Choose Section */}

      {/* Start Team Section */}
      {/* <Spacing lg="145" md="80" />
      <Div className="container">
        <SectionHeading
          title="Awesome team <br/>members"
          subtitle="Our Team"
          variant="cs-style1"
        />
        <Spacing lg="85" md="45" />
        <TeamSlider />
      </Div> */}
      {/* End Team Section */}

      {/* Start CTA Section */}
      <Spacing lg="150" md="80" />
      <Div className="container">
        <Cta
          title="Let's collaborate and create <br />something awesome together."
          btnText="Apply For Meeting"
          btnLink="/contact"
          bgSrc="/images/boxx4.jpg"
        />
      </Div>
      {/* End CTA Section */}
    </>
  );
}
