import React from 'react'
import Div from '../Div'
import AuthorWidget from '../Widget/AuthorWidget'
import RecentPost from '../Widget/RecentPost'
import SearchWidget from '../Widget/SearchWidget'
import SideMenuWidget from '../Widget/SideMenuWidget'
import TagWidget from '../Widget/TagWidget'

export default function Sidebar() {
  const tagData = [
    {
      title: 'Business',
      url:'/blog/blog-details'
    },
    {
      title: 'Agency',
      url:'/blog/blog-details'
    },
    {
      title: 'NFT',
      url:'/blog/blog-details'
    },
    {
      title: 'Marketing',
      url:'/blog/blog-details'
    },
    {
      title: 'Information',
      url:'/blog/blog-details'
    },
    {
      title: 'Game-Fi',
      url:'/blog/blog-details'
    },
    {
      title: 'Blockchain',
      url:'/blog/blog-details'
    },
  ]
  const archiveData = [
    {
      title: 'Archives',
      url:'/blog/blog-details'
    },
    {
      title: '18 May 2023',
      url:'/blog/blog-details'
    },
    {
      title: '23 March 2023',
      url:'/blog/blog-details'
    },
    {
      title: '4 March 2023',
      url:'/blog/blog-details'
    },
    {
      title: '5 March 2023',
      url:'/blog/blog-details'
    }
  ]
  const categoryData = [
    {
      title: 'Business',
      url:'/blog/blog-details'
    },
    {
      title: 'Marketing',
      url:'/blog/blog-details'
    },
    {
      title: 'Gamefi',
      url:'/blog/blog-details'
    },
    {
      title: 'NFT',
      url:'/blog/blog-details'
    },
    {
      title: 'Blockchain',
      url:'/blog/blog-details'
    }
  ]
  const recentPostData = [
    {
      title: 'EDCON 2023:The Ultimate...',
      thumb: '/images/recent_post_1.jpeg',
      href: '/blog/blog-details',
      date: '18 May 2023'
    },
    {
      title: 'ChainPeak: An Expert...',
      thumb: '/images/recent_post_2.jpeg',
      href: '/blog/blog-details',
      date: '23 March 2023'
    },
    {
      title: 'ChainPeak’s Marketing Services...',
      thumb: '/images/recent_post_3.jpeg',
      href: '/blog/blog-details',
      date: '4 March 2023'
    },
    {
      title: 'You should now add...',
      thumb: '/images/recent_post_4.jpeg',
      href: '/blog/blog-details',
      date: '5 March 2023'
    },
  ]
  return (
    <>
      <Div className="cs-sidebar_item">
         {/* <AuthorWidget 
          src='/images/avatar_1.png'
          name='Kuber Jontra' 
          // description='At vero eos et accusamus et iusto odio dignissimos ducimus qui blanditiis praesentium voluptatum.'  */}
        {/* /> */}
      </Div>
      <Div className="cs-sidebar_item">
        <SearchWidget title='Search'/>
      </Div>
      <Div className="cs-sidebar_item">
        <SideMenuWidget title='Categories' data={categoryData}/>
      </Div>
      <Div className="cs-sidebar_item">
        <RecentPost title='Archives' data={recentPostData}/>
      </Div>
      <Div className="cs-sidebar_item">
        <SideMenuWidget title='Archives' data={archiveData}/>
      </Div>
      <Div className="cs-sidebar_item">
        <TagWidget title='Tags' data={tagData}/>
      </Div>
    </>
  )
}
