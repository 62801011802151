import React, { useEffect } from 'react'
import { pageTitle } from '../../helper'
import Cta from '../Cta'
import PageHeading from '../PageHeading'
import Pagination from '../Pagination'
import PostStyle2 from '../Post/PostStyle2'
import Div from '../Div'
import Sidebar from '../Sidebar.jsx'
import Spacing from '../Spacing'

export default function BlogPage() {
  pageTitle('Blog');
  const postData = [
    {
      thumb:'/images/post_4.jpeg',
      title:'ChainPeak: An Expert Firm That Strives in the Complex World of Marketing',
      subtitle:'Nowadays, having outstanding ideas and technical talent is not enough to achieve success in the business field. Marketing has become an increasingly critical aspect of any successful crypto project, and many projects often struggle to master this key element. This is where companies like ChainPeak come into play. Through our comprehensive project management and constantly updated strategies, we help companies of all sizes overcome marketing barriers and achieve our shared goals.',
      date:'23 Mar 2023',
      category:'Marketing',
      categoryHref:'/blog',
      href:'/blog/blog-details'
    },
    {
      thumb:'/images/post_5.jpeg',
      title:'Creative studio programm coming soon',
      subtitle:'',
      date:'05 Mar 2022',
      category:'Photography',
      categoryHref:'/blog',
      href:'/blog/blog-details'
    },
    {
      thumb:'/images/post_6.jpeg',
      title:'ChainPeak Marketing Services: The Professional Touch Your Project Needs',
      subtitle:'In the world of business, marketing is essential for any project to succeed. This is where ChainPeak’s professional marketing services come in. With years of experience in the industry, ChainPeak has the expertise and know-how to take your project to new heights. ',
      date:'04 Mar 2022',
      category:'Tech',
      categoryHref:'/blog',
      href:'/blog/blog-details'
    }
  ]

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  
  return (
    <>
      <PageHeading 
        title='Our Blog'
        bgSrc='/images/blog_hero_bg.jpeg'
        pageLinkText='Blog'
      />
      <Spacing lg='150' md='80'/>
      <Div className="container">
        <Div className="row">
          <Div className="col-lg-8">
            {postData.map((item, index)=> (
              <Div key={index}>
                <PostStyle2 
                  thumb={item.thumb}
                  title={item.title}
                  subtitle={item.subtitle}
                  date={item.date}
                  category={item.category}
                  categoryHref={item.categoryHref}
                  href={item.href}
                />
                {postData.length>index+1 && <Spacing lg='95' md='60'/>}
              </Div>
            ))}
            <Spacing lg='60' md='40'/>
            <Pagination/>
          </Div>
          <Div className="col-xl-3 col-lg-4 offset-xl-1">
            <Spacing lg='0' md='80'/>
            <Sidebar/>
          </Div>
        </Div>
      </Div>
      <Spacing lg='150' md='80'/>
      <Div className="container">
        <Cta 
          title="Let's collaborate and create <br />something awesome together." 
          btnText='Apply For Meeting' 
          btnLink='/contact' 
          bgSrc='/images/cta_bg.jpeg'
        />
      </Div>
    </>
  )
}
